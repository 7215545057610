@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Cairo';
}

.no-tailwindcss {
  all: unset;
}

.tox-tinymce {
  @apply !rounded-none !border-2 !border-muted-500;
}
.tox-statusbar {
  @apply !hidden;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  @apply !p-0;
}
